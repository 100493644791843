import { useStaticQuery, graphql } from "gatsby"
import React, { useRef, useState } from "react"

import { clamp } from "../utils.js"
import useViewportState from "../hooks/useViewportState.js"

import styles from "./ecosystemGradients.module.scss"

// Circle gradients
// #2591B8 to #008070
// #F2E324 to #E26938
// #D491E5 to #3399CC

const EcosystemGradients = ({ parentRef }) => {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          zeusImg: asset(id: 24807) {
            url
            width
            height
            ... on Craft_athenaContent_Asset {
              altText
            }
          }
          hellenImg: asset(id: 24805) {
            url
            width
            height
            ... on Craft_athenaContent_Asset {
              altText
            }
          }
          demosImg: asset(id: 24804) {
            url
            width
            height
            ... on Craft_athenaContent_Asset {
              altText
            }
          }
          nostosImg: asset(id: 24806) {
            url
            width
            height
            ... on Craft_athenaContent_Asset {
              altText
            }
          }
        }
      }
    `
  )

  const [activeImage, setActiveImage] = useState(-1)
  const ref = useRef(null)
  const imageRef1 = useRef(null)
  const imageRef2 = useRef(null)
  const imageRef3 = useRef(null)
  const imageRef4 = useRef(null)
  const images = [imageRef1, imageRef2, imageRef3, imageRef4]
  const { zeusImg, hellenImg, demosImg, nostosImg } = data.craft

  useViewportState(
    scrollTop => {
      let node = ref.current
      let parent = parentRef.current
      if (node !== null && parent !== null && node.offsetHeight !== 0) {
        // const winHeight = window !== "undefined" ? window.innerHeight : 0
        const parentOffsetTop = parent.offsetTop
        const elementHeight = node.getBoundingClientRect().height
        const halfElementHeight = elementHeight / 2
        const parentHeight = parent.getBoundingClientRect().height
        const scrollWithinRange =
          (scrollTop - parentOffsetTop + halfElementHeight) / parentHeight
        const scrollPercentage = clamp(0, 1, scrollWithinRange)
        const percentRange = 1 / images.length

        images.map((img, index) => {
          const imgNum = index + 1
          const bottom = imgNum * percentRange
          const top = bottom - percentRange

          if (img.current == null) {
            return img
          }
          if (scrollPercentage > top && scrollPercentage < bottom) {
            setActiveImage(index)
          } else if (scrollWithinRange < 0 || scrollWithinRange > 1) {
            setActiveImage(-1)
          }
        })
      }
    },
    state => state.scrollTop
  )

  return (
    <div ref={ref} className={styles.container}>
      <div
        ref={imageRef1}
        className={`${styles.image} ${
          activeImage === 0 ? styles.isActive : ""
        }`}
      >
      {zeusImg && (
        <img src={zeusImg.url} alt="" />
      )}
      </div>
      <div
        ref={imageRef2}
        className={`${styles.image} ${
          activeImage === 1 ? styles.isActive : ""
        }`}
      >
      {hellenImg && (
        <img src={hellenImg.url} alt="" />
      )}
      </div>
      <div
        ref={imageRef3}
        className={`${styles.image} ${
          activeImage === 2 ? styles.isActive : ""
        }`}
      >
      {demosImg && (
        <img src={demosImg.url} alt="" />
      )}
      </div>
      <div
        ref={imageRef4}
        className={`${styles.image} ${
          activeImage === 3 ? styles.isActive : ""
        }`}
      >
      {nostosImg && (
        <img src={nostosImg.url} alt="" />
      )}
      </div>
    </div>
  )
}

export default EcosystemGradients
