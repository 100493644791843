import React from "react"

const DemosLogo = ({ color = "#FFFFFF", id = "demos-logo", width = 222 }) => {
  return (
    <>
      <svg
        width={`${width}px`}
        viewBox="0 0 222 36"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby={id}
        role="img"
      >
        <title id={id}>Nostos</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-600.000000, -570.000000)" fill={color}>
            <g transform="translate(600.000000, 570.000000)">
              <path d="M127,35 C128.447973,33.4341084 128.892493,32.4641083 128.892493,30.4575253 L128.892493,0 L136.107507,0 L136.107507,30.5169228 C136.107507,32.4789577 136.552496,33.4369825 138,35 L127,35 Z"></path>
              <path d="M167.764262,34.993301 C160.166194,33.1625538 155,26.3654385 155,17.5002393 C155,8.63456149 160.166194,1.83744617 167.764262,0.00669902249 C163.884575,2.4121266 163.526556,8.78911751 163.526556,17.5002393 C163.526556,26.2108825 163.884575,32.5878734 167.764262,34.993301 M176.25541,35 C180.117884,32.5993574 180.473935,26.2185385 180.473935,17.5002393 C180.473935,8.78146148 180.117884,2.40064256 176.25541,0 C183.936589,1.80251555 189,8.49675302 189,17.5002393 C189,26.503247 183.936589,33.1974844 176.25541,35"></path>
              <path d="M213.682381,35 C215.326012,33.924501 216.283729,32.0335506 216.283729,29.6058049 C216.283729,25.2727942 213.262856,23.2978651 207.47371,19.900548 C202.837765,17.1869888 199,14.4906069 199,8.96185516 C199,4.28052405 201.916977,1.10126513 206.997067,0 C205.489338,0.988180281 204.596617,2.66011833 204.596617,4.7500409 C204.596617,8.65122969 207.809527,10.8613944 213.102334,14.0373132 C218.619675,17.3096166 222,19.591354 222,25.0380358 C222,30.2766796 218.962877,33.8166648 213.682381,35"></path>
              <polygon points="210 0 219 0 219 7"></polygon>
              <polygon points="210 36 201 36 201 29"></polygon>
              <polygon points="141 0 150 0 150 7"></polygon>
              <polygon points="126 0 117 0 117 7"></polygon>
              <path d="M4,19 C4.40092593,20.9800701 5.75509259,32.3348773 8,35 L0,35 C2.24490741,32.3348773 3.59861111,20.9800701 4,19"></path>
              <polygon points="23.739522 36 4.14539203 5.73884088 4.14539203 5.72391161 3.79506918 5.19753426 3.70161845 5.05353899 3.70161845 5.0578733 1 1 10.1506583 1 29 29.9416038 29 36"></polygon>
              <path d="M30,17 C29.5990741,15.0199299 28.2449074,3.66512266 26,1 L34,1 C31.7550926,3.66512266 30.4013889,15.0199299 30,17"></path>
              <path d="M55.7642617,34.993301 C48.1661942,33.1625538 43,26.3654385 43,17.5002393 C43,8.63456149 48.1661942,1.83744617 55.7642617,0.00669902249 C51.8845753,2.4121266 51.5260646,8.78911751 51.5260646,17.5002393 C51.5260646,26.2108825 51.8845753,32.5878734 55.7642617,34.993301 M64.2554096,35 C68.1178836,32.5993574 68.4739354,26.2185385 68.4739354,17.5002393 C68.4739354,8.78146148 68.1178836,2.40064256 64.2554096,0 C71.9365888,1.80251555 77,8.49675302 77,17.5002393 C77,26.503247 71.9365888,33.1974844 64.2554096,35"></path>
              <path d="M101.044016,35 C102.616185,33.924501 103.532263,32.0335506 103.532263,29.6058049 C103.532263,25.2727942 100.642732,23.2978651 95.1052879,19.900548 C90.6709056,17.1869888 87,14.4906069 87,8.96185516 C87,4.28052405 89.790152,1.10126513 94.6493684,0 C93.2071933,0.988180281 92.3532862,2.66011833 92.3532862,4.7500409 C92.3532862,8.65122969 95.426504,10.8613944 100.489189,14.0373132 C105.766645,17.3096166 109,19.591354 109,25.0380358 C109,30.2766796 106.094926,33.8166648 101.044016,35"></path>
              <polygon points="98 0 107 0 107 7"></polygon>
              <polygon points="97 36 88 36 88 29"></polygon>
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export default DemosLogo
