import React from "react"

const DemosLogo = ({ color = "#FFFFFF", id = "demos-logo", width = 204 }) => {
  return (
    <>
      <svg
        width={`${width}px`}
        viewBox="0 0 204 38"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby={id}
        role="img"
      >
        <title id={id}>Demos</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-618.000000, -569.000000)"
            fill={color}
            fillRule="nonzero"
          >
            <g transform="translate(618.000000, 569.000000)">
              <path d="M13.9477213,34.2053058 L10.8850971,34.2053058 C10.3320203,34.2053058 9.88214325,33.7501521 9.88214325,33.1893376 L9.88214325,3.81003717 C9.88214325,3.24922271 10.3320203,2.79406894 10.8850971,2.79406894 L13.9477213,2.79406894 C22.4799353,2.79406894 26.2606324,9.46944914 26.2606324,18.5262589 C26.2606324,27.4780331 22.4799353,34.1534133 13.9477213,34.2053058 M15.0958964,0 L0.49837634,0 C0.0676561862,0 -0.15172065,0.523927002 0.14675544,0.837157824 L2.3670962,3.1704461 L2.3670962,33.7751605 L0.135014144,36.1647178 C-0.16037213,36.4810747 0.0608585941,37 0.489724859,37 L15.0958964,37 C26.6425335,37 34,29.5481075 34,18.5262589 C34,7.45126732 26.6425335,0 15.0958964,0"></path>
              <path d="M64.2182399,14.109143 L58.1865564,19.0944445 C57.9378145,19.3002984 57.9378145,19.6990187 58.1865564,19.905522 L64.2182399,24.8901741 C64.5362129,25.153823 65,24.9129024 65,24.4849601 L65,14.514357 C65,14.087064 64.5362129,13.8467929 64.2182399,14.109143"></path>
              <path d="M72.1833442,28.7322068 L64.4792993,34.3972457 C64.1412838,34.6460798 63.7277953,34.7805002 63.3033407,34.7805002 L55.3632011,34.7805002 C54.7845752,34.7805002 54.3156109,34.3253464 54.3156109,33.7645319 L54.3156109,3.18295032 C54.3156109,2.62213586 54.7845752,2.16698209 55.3632011,2.16698209 L62.7776104,2.16698209 C63.1562652,2.16698209 63.5271792,2.27514363 63.8445526,2.47646164 L70.9041747,6.96610341 C71.2428353,7.18117607 71.6930926,6.94547144 71.6930926,6.55221359 L71.6930926,0.739624873 C71.6930926,0.330736735 71.3518517,0 70.9312675,0 L44.5209013,0 C44.070644,0 43.8416449,0.523927002 44.1545028,0.837783035 L46.4715864,3.17107131 L46.4715864,33.7764109 L44.1409564,36.165343 C43.832614,36.4816999 44.0635483,37 44.5105802,37 L72.2381749,37 C72.6600493,37 73,36.6692633 73,36.2610003 L73,29.1267151 C73,28.717827 72.5187794,28.4852484 72.1833442,28.7322068"></path>
              <path d="M185.943469,7.70380932 C185.943469,4.32046276 188.43223,1.97767228 192.244067,1.97767228 C192.474194,1.97767228 192.722506,1.9875237 192.978343,2.01584653 C193.551466,2.07864932 194.091983,2.3120048 194.550357,2.65557302 L201.351968,7.74383071 C201.678034,7.98826903 202.147694,7.75922355 202.147694,7.3559311 L202.147694,2.32185621 C202.147694,2.10758786 202.044231,1.90009236 201.856743,1.78987961 C200.066516,0.742550675 195.896006,0 192.244067,0 C184.566467,0 179.112387,4.32046276 179.112387,10.4628222 C179.112387,22.1232075 197.169545,20.1966395 197.169545,29.0980119 C197.169545,33.2626991 193.833639,35.9699921 189.914577,35.9699921 C189.382838,35.9699921 188.817866,35.9047264 188.348206,35.8197579 C187.840295,35.7280166 187.364364,35.5199054 186.951765,35.2138957 L178.793845,29.1614304 C178.467779,28.9188392 178,29.1478847 178,29.5499457 L178,35.2348299 C178,35.4632597 178.119139,35.6781438 178.322304,35.7871251 C180.594733,37.0068539 184.869332,38 188.856116,38 C197.805373,38 204,33.2109791 204,26.338999 C204,14.6792294 185.943469,16.5528461 185.943469,7.70380932"></path>
              <path d="M122.870591,0.831502822 C123.15588,0.515156635 122.93872,0 122.518999,0 L115.756623,0 C115.327777,0 114.932388,0.241323375 114.725569,0.628941232 L103.835936,21.1151702 C103.644932,21.4740293 103.144916,21.4802812 102.946613,21.1251732 L91.4608546,0.606434389 C91.2516023,0.231945524 90.8629041,0 90.4425747,0 L83.4812875,0 C83.1126629,0 82.8815121,0.4101247 83.0633913,0.740225068 L85.2824384,4.7451928 C85.4399859,5.03027948 85.5227135,5.35287756 85.5227135,5.67985198 L85.5227135,32.2172958 C85.5227135,32.6111656 85.4028801,32.9962827 85.1802455,33.3170052 L83.1613262,36.2166368 C82.9338252,36.5436112 83.1613262,37 83.5524576,37 L91.3337216,37 C91.7650003,37 91.9779023,36.460461 91.6688902,36.1503667 L88.9029891,33.378899 C88.5483551,33.023791 88.3482273,32.5361427 88.3482273,32.027238 L88.3482273,11.5722686 C88.3482273,11.0389814 89.038638,10.8564259 89.2880375,11.3228177 L100.329744,31.954716 C100.513448,32.2979453 100.992781,32.2960698 101.173444,31.95159 L112.371481,10.6207293 C112.619664,10.1505863 113.313724,10.3306411 113.313724,10.866429 L113.313724,33.0231658 C113.313724,33.5058126 113.134887,33.9703288 112.811276,34.3216856 L111.115359,36.164746 C110.825812,36.480467 111.042973,37 111.464518,37 L122.518999,37 C122.93872,37 123.15588,36.4842182 122.870591,36.1684972 L121.20144,34.3185597 C120.885736,33.9690784 120.711157,33.5101889 120.711157,33.0344192 L120.711157,3.96620594 C120.711157,3.4891859 120.885736,3.03029637 121.20144,2.6814403 L122.870591,0.831502822 Z"></path>
              <path d="M152.145441,35.6577873 C145.261158,35.6577873 141.228025,29.1514356 141.228025,17.9588113 C141.228025,7.59987686 144.616035,2.29049193 150.854559,2.29049193 C157.738205,2.29049193 161.826107,8.79745933 161.826107,19.9900836 C161.826107,30.3477866 158.384602,35.6577873 152.145441,35.6577873 M151.98432,0 C141.765521,0 133,7.13192689 133,19.2081146 C133,30.5041804 140.52877,38 151.016317,38 C161.287974,38 170,30.8163523 170,18.7395489 C170,7.49581956 162.471867,0 151.98432,0"></path>
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export default DemosLogo
