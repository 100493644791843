import React, { useRef } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MarqueeText from "../components/marqueeText"
import HeadingCta from "../components/zeus-modules/headingCta"
import styles from "./ecosystem.module.scss"
import LogoZeus from "../components/logo"
import LogoDemos from "../components/logoDemos"
import LogoNostos from "../components/logoNostos"
import Gradients from "../components/ecosystemGradients"

export const query = graphql`
  query {
    craft {
      page: entry(section: "ecosystem") {
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
      }
    }
  }
`

const EcosystemPage = ({ data }) => {
  const page = data.craft.page
  const seo = page.seomatic
  const ref = useRef()

  return (
    <Layout seo={seo}>
      <div className={`${styles.container} page-content-module`}>
        <div className={styles.title}>
          <div className="wrapper">
            <div className={styles.pageTitle}>
              <h1 className="h-2 h--cms-title">
                Our <em>ecosystem</em>
              </h1>
            </div>
          </div>
          <MarqueeText direction={1}>
            <h2 className={styles.heading}>
              Shaping business as a force for good.&nbsp;
            </h2>
          </MarqueeText>
          <div className="wrapper">
            <div className="columns is-tablet is-centered">
              <div className="column is-8-tablet is-6-widescreen">
                <div className="text-center user-content body-large-after-tablet">
                  <p>
                    When business prioritizes people, everyone profits. We are
                    building cooperative and transformative ways for business to
                    advance humankind, using all the ingredients of a business
                    ecosystem: ideas, talent and capital.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ref={ref} className={styles.body}>
          <div className={styles.gradients}>
            <Gradients parentRef={ref} />
          </div>
          <div className={styles.ecosystems}>
            <div className={styles.ecosystemsItem}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-8-tablet is-6-widescreen">
                    <div className="text-center">
                      <h2 className="mb-2">
                        <LogoZeus width={154} />
                      </h2>
                      <div className="mb-4">
                        <div className={styles.pill}>
                          <h3 className="subhead subhead--small fw-bold">
                            Ideas
                          </h3>
                        </div>
                      </div>
                      <div className="user-content body-large-after-tablet">
                        <p>
                          Zeus Jones is a business and brand innovation engine
                          that helps organizations imagine and build the
                          essential industries of the future.
                        </p>
                        {/* <p>
                          <Link to="/about">Learn more</Link>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.ecosystemsItem}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-8-tablet is-6-widescreen">
                    <div className="text-center">
                      <h2 className="h-2 mb-2">Hellen</h2>
                      <div className="mb-4">
                        <div className={styles.pill}>
                          <h3 className="subhead subhead--small fw-bold">
                            Growth
                          </h3>
                        </div>
                      </div>
                      <div className="user-content body-large-after-tablet">
                        <p>
                          Hellen is a loyalty consultancy that builds deep
                          belonging into the core of your business. From
                          strategy to execution, we ensure your customer
                          relationships are meaningful and profitable.
                        </p>
                        <p>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.heyhellen.com"
                          >
                            Learn more
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.ecosystemsItem}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-8-tablet is-6-widescreen">
                    <div className="text-center">
                      <h2 className="mb-2">
                        <LogoDemos />
                      </h2>
                      <div className="mb-4">
                        <div className={styles.pill}>
                          <h3 className="subhead subhead--small fw-bold">
                            Capital
                          </h3>
                        </div>
                      </div>
                      <div className="user-content body-large-after-tablet">
                        <p>
                          Demos is a strategic investment partner that aligns
                          and accelerates the winning ecosystem members of the
                          industries of tomorrow. Demos is focused on areas like
                          food, healthcare, agriculture, and human creativity.
                        </p>
                        <p>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.demosfunds.io"
                          >
                            Learn more
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.ecosystemsItem}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-8-tablet is-6-widescreen">
                    <div className="text-center">
                      <h2 className="mb-2">
                        <LogoNostos />
                      </h2>
                      <div className="mb-4">
                        <div className={styles.pill}>
                          <h3 className="subhead subhead--small fw-bold">
                            Talent
                          </h3>
                        </div>
                      </div>
                      <div className="user-content body-large-after-tablet">
                        <p>
                          A community of best-in-class independent companies
                          with complementary skillsets and diverse industry
                          expertise who all share similar values, including a
                          passion for working together and remaining
                          independent.
                        </p>
                        <p>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.nostos.network"
                          >
                            Learn more
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HeadingCta
          data={{
            body: null,
            heading: "Want to <em>know more?</em>",
            links: [
              {
                button: false,
                text: "Let’s chat",
                page: [
                  {
                    title: "Contact Us",
                    uri: "contact",
                    id: "10",
                  },
                ],
              },
            ],
          }}
        />
      </div>
    </Layout>
  )
}

export default EcosystemPage
